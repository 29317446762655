import { cloneElement, isValidElement } from "react";
import { StyledTag } from "./StyledTag";
import { type TagProps, scales } from "./types";

const Tag = ({ startIcon, endIcon, children, ...props }: TagProps) => (
	<StyledTag {...props}>
		{isValidElement(startIcon) &&
			cloneElement(startIcon, {
				// @ts-expect-error: TODO
				mr: "0.5em",
			})}
		{children}
		{isValidElement(endIcon) &&
			cloneElement(endIcon, {
				// @ts-expect-error: TODO
				ml: "0.5em",
			})}
	</StyledTag>
);

/* eslint-disable react/default-props-match-prop-types */
Tag.defaultProps = {
	variant: "primary",
	scale: scales.MD,
	outline: false,
};

export default Tag;
