import type { TokenInfo } from "@uniswap/token-lists";
import type { Token } from "@vapordex/sdk";
import { useMemo } from "react";
import { isAddress } from "../../utils";

export function filterTokens(tokens: Token[], search: string): Token[] {
	if (search.length === 0) return tokens;

	const searchingAddress = isAddress(search);

	if (searchingAddress) {
		return tokens.filter((token) => token.address === searchingAddress);
	}

	const lowerSearchParts = search
		.toLowerCase()
		.split(/\s+/)
		.filter((s) => s.length > 0);

	if (lowerSearchParts.length === 0) {
		return tokens;
	}

	const matchesSearch = (s: string): boolean => {
		const sParts = s
			.toLowerCase()
			.split(/\s+/)
			.filter((s_) => s_.length > 0);

		return lowerSearchParts.every(
			(p) =>
				p.length === 0 ||
				sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)),
		);
	};

	return tokens.filter((token) => {
		const { name, symbol } = token;
		return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name));
	});
}

export function createFilterToken<T extends TokenInfo | Token>(
	search: string,
): (token: T) => boolean {
	const searchingAddress = isAddress(search);

	if (searchingAddress) {
		const address = searchingAddress.toLowerCase();
		return (t: T) => "address" in t && address === t.address.toLowerCase();
	}

	const lowerSearchParts = search
		.toLowerCase()
		.split(/\s+/)
		.filter((s) => s.length > 0);

	if (lowerSearchParts.length === 0) {
		return () => true;
	}

	const matchesSearch = (s: string): boolean => {
		const sParts = s
			.toLowerCase()
			.split(/\s+/)
			.filter((s_) => s_.length > 0);

		return lowerSearchParts.every(
			(p) =>
				p.length === 0 ||
				sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)),
		);
	};
	return (token) => {
		const { name, symbol } = token;
		return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name));
	};
}

export function useSortedTokensByQuery(
	tokens: Token[] | undefined,
	searchQuery: string,
): Token[] {
	return useMemo(() => {
		if (!tokens) {
			return [];
		}

		const trimmedSearchQuery = searchQuery.toLowerCase().trim();

		const symbolMatch = trimmedSearchQuery
			.split(/\s+/)
			.filter((s) => s.length > 0);

		if (symbolMatch.length > 1) {
			return tokens;
		}

		const exactMatches: Token[] = [];
		const symbolSubstrings: Token[] = [];
		const rest: Token[] = [];

		// sort tokens by exact match -> substring on symbol match -> rest
		for (const token of tokens) {
			const tokenSymbol = token.symbol?.toLowerCase();
			if (
				tokenSymbol === symbolMatch[0] ||
				token.name?.toLowerCase() === trimmedSearchQuery
			) {
				exactMatches.push(token);
				continue;
			}
			if (tokenSymbol.startsWith(trimmedSearchQuery)) {
				symbolSubstrings.push(token);
				continue;
			}
			rest.push(token);
		}

		return [...exactMatches, ...symbolSubstrings, ...rest];
	}, [tokens, searchQuery]);
}
