import type { Currency, CurrencyAmount, TradeType } from "@vapordex/sdk";
import type { Trade } from "@vapordex/sdk";
import { ROUTER_ADDRESS_MAP } from "@vapordex/sdk";
import { type Hash, waitForTransaction } from "@wagmi/core";
import { getChainIdCookie } from "config/constants/networks";
import useActiveWagmi from "hooks/useActiveWagmi";
import { useCallback, useMemo, useState } from "react";
import { type Address, type TransactionReceipt, maxUint256 } from "viem";
import useToast from "../contexts/ToastsContext/useToast";
import { Field } from "../state/swap/actions";
import { useHasPendingApproval } from "../state/transactions/hooks";
import { computeSlippageAdjustedAmounts } from "../utils/exchange";
import useTokenAllowance from "./useTokenAllowance";
import { useWagmiWrite } from "./useWagmiWrite";

export enum ApprovalState {
	UNKNOWN = 0,
	NOT_APPROVED = 1,
	PENDING = 2,
	APPROVED = 3,
}

export function useApproveCallback(
	amountToApprove?: CurrencyAmount<Currency>,
	spender?: string,
): [ApprovalState, () => Promise<void>, CurrencyAmount<Currency>, () => void] {
	const { account, chainId } = useActiveWagmi();
	const [isApproved, setIsApproved] = useState(false);
	const { toastError } = useToast();

	const token = amountToApprove?.currency?.isToken
		? amountToApprove.currency
		: undefined;
	const currentAllowance = useTokenAllowance(
		token,
		account ?? undefined,
		spender,
	);
	const pendingApproval = useHasPendingApproval(token?.address, spender);

	const approvalState: ApprovalState = useMemo(() => {
		if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
		if (amountToApprove.currency.isNative || isApproved)
			return ApprovalState.APPROVED;
		if (!currentAllowance) return ApprovalState.UNKNOWN;

		return currentAllowance.lessThan(amountToApprove)
			? pendingApproval
				? ApprovalState.PENDING
				: ApprovalState.NOT_APPROVED
			: ApprovalState.APPROVED;
	}, [amountToApprove, currentAllowance, pendingApproval, spender, isApproved]);

	const resetApproval = useCallback(() => {
		setIsApproved(false);
	}, []);

	const { writeAsync } = useWagmiWrite({
		address: token?.address as Address,
		args: [
			spender,
			amountToApprove ? amountToApprove.quotient.toString() : maxUint256,
		],
		contractName: "erc20",
		functionName: "approve",
		onSuccessOverrides: {
			approval: { spender, tokenAddress: token?.address },
			summary: `Approve ${amountToApprove?.currency?.symbol}`,
			type: "approve",
		},
	});

	const approve = useCallback(async (): Promise<void> => {
		if (approvalState !== ApprovalState.NOT_APPROVED) {
			toastError("Error", "Approve was called unnecessarily");
			console.error("approve was called unnecessarily");
			return;
		}
		if (!token) {
			toastError("Error", "No token");
			console.error("no token");
			return;
		}

		if (!amountToApprove) {
			toastError("Error", "Missing amount to approve");
			console.error("missing amount to approve");
			return;
		}

		if (!spender) {
			toastError("Error", "No spender");
			console.error("no spender");
			return;
		}

		return writeAsync()
			.then((response) => {
				const txHash = response.hash;
				waitForTransaction({ chainId, hash: txHash as Hash })
					.then((receipt: TransactionReceipt) => {
						if (receipt && receipt.status === "success") {
							setIsApproved(true);
						}
					})
					.catch((error) => {
						toastError("Transaction Error", error.message);
					});
			})
			.catch((error: any) => {
				if (error?.code !== 4001) {
					toastError("Error", error.message);
				}
				throw error;
			});
	}, [
		approvalState,
		token,
		amountToApprove,
		spender,
		writeAsync,
		toastError,
		setIsApproved,
		chainId,
	]);

	return [approvalState, approve, currentAllowance, resetApproval];
}

export function useApproveCallbackFromTrade(
	trade?: Trade<Currency, Currency, TradeType>,
	allowedSlippage = 0,
) {
	const amountToApprove = useMemo(
		() =>
			trade
				? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT]
				: undefined,
		[trade, allowedSlippage],
	);
	return useApproveCallback(
		amountToApprove,
		ROUTER_ADDRESS_MAP[getChainIdCookie()],
	);
}
