import { LightGreyCard } from "@components/Card";
import QuestionHelper from "@components/QuestionHelper";
import { useIsUserAddedToken } from "@hooks/Tokens";
import { useCombinedActiveList } from "@state/lists/hooks";
import { useCurrencyBalance } from "@state/wallet/hooks";
import { isTokenOnList } from "@utils";
import { type Currency, type CurrencyAmount, Token } from "@vapordex/sdk";
import { Text } from "@vapordex/uikit";
import { commify } from "@vaporfi/hooks";
import useActiveWagmi from "hooks/useActiveWagmi";
import {
	type CSSProperties,
	type MutableRefObject,
	useCallback,
	useMemo,
} from "react";
import { FixedSizeList } from "react-window";
import styled from "styled-components";
import { wrappedCurrency } from "utils/wrappedCurrency";
import Column from "../Layout/Column";
import { RowBetween, RowFixed } from "../Layout/Row";
import CircleLoader from "../Loader/CircleLoader";
import { CurrencyLogo } from "../Logo";
import ImportRow from "./ImportRow";

function currencyKey(currency: Currency): string {
	return currency instanceof Token
		? currency?.address
		: currency?.isNative
			? "ETHER"
			: "";
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
`;

const FixedContentRow = styled.div`
  padding: 8px 20px;
  height: 56px;
  display: grid;
  grid-gap: 16px;
  align-items: center;
`;

function Balance({ balance }: { balance: CurrencyAmount<Currency> }) {
	if (balance.toExact() === "0") {
		return null;
	}
	return (
		<StyledBalanceText title={balance.toExact()}>
			{commify(balance.toSignificant(8))}
		</StyledBalanceText>
	);
}

const MenuItem = styled(RowBetween)<{ disabled: boolean; selected: boolean }>`
  padding: 4px 25px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0, 72px);
  grid-gap: 8px;
  cursor: ${({ disabled }) => !disabled && "pointer"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  :hover {
    background-color: ${({ disabled, theme }) =>
			!disabled && theme.colors.background};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`;

function CurrencyRow({
	currency,
	isSelected,
	onSelect,
	otherSelected,
	style,
}: {
	currency: Currency;
	onSelect: () => void;
	isSelected: boolean;
	otherSelected: boolean;
	style: CSSProperties;
}) {
	const { account } = useActiveWagmi();
	const key = currencyKey(currency);
	const selectedTokenList = useCombinedActiveList();
	const isOnSelectedList = isTokenOnList(selectedTokenList, currency);
	const customAdded = useIsUserAddedToken(currency);
	const balance = useCurrencyBalance(account ?? undefined, currency);

	// only show add or remove buttons if not on selected list
	return (
		<MenuItem
			style={style}
			className={`token-item-${key}`}
			data-testid={`token-item-${key}`}
			onClick={() => (isSelected ? null : onSelect())}
			disabled={isSelected}
			selected={otherSelected}
		>
			<CurrencyLogo currency={currency} size={32} />
			<Column>
				<Text bold fontSize={"16px"} lineHeight={1}>
					{currency?.symbol}
				</Text>
				<Text
					color="textSubtle"
					small
					ellipsis
					maxWidth="200px"
					fontSize={"13px"}
					lineHeight={1.2}
				>
					{!isOnSelectedList && customAdded && `${"Added by user"} •`}{" "}
					{currency?.name}
				</Text>
			</Column>
			<RowFixed style={{ justifySelf: "flex-end" }}>
				{balance ? (
					<Balance balance={balance} />
				) : account ? (
					<CircleLoader />
				) : null}
			</RowFixed>
		</MenuItem>
	);
}

export default function CurrencyList({
	breakIndex,
	currencies,
	fixedListRef,
	inactiveCurrencies,
	onCurrencySelect,
	otherCurrency,
	selectedCurrency,
	setImportToken,
	showImportView,
	showNative,
}: {
	height: number;
	currencies: Currency[];
	inactiveCurrencies: Currency[];
	selectedCurrency?: Currency | null;
	onCurrencySelect: (currency: Currency) => void;
	otherCurrency?: Currency | null;
	fixedListRef?: MutableRefObject<FixedSizeList | undefined>;
	showNative: boolean;
	showImportView: () => void;
	setImportToken: (token: Token) => void;
	breakIndex: number | undefined;
}) {
	const { chainId, nativeCurrency } = useActiveWagmi();
	const itemData: (Currency | undefined)[] = useMemo(() => {
		let formatted: (Currency | undefined)[] = showNative
			? [nativeCurrency, ...currencies, ...inactiveCurrencies]
			: [...currencies, ...inactiveCurrencies];
		if (breakIndex !== undefined) {
			formatted = [
				...formatted.slice(0, breakIndex),
				undefined,
				...formatted.slice(breakIndex, formatted.length),
			];
		}
		return formatted;
	}, [showNative, nativeCurrency, currencies, inactiveCurrencies, breakIndex]);

	const Row = useCallback(
		({ data, index, style }) => {
			const currency: Currency = data[index];
			const isSelected = Boolean(selectedCurrency?.equals(currency));
			const otherSelected = Boolean(otherCurrency?.equals(currency));
			const handleSelect = () => onCurrencySelect(currency);

			const token = wrappedCurrency(currency, chainId);
			const showImport = index > currencies.length;
			if (index === breakIndex || !data) {
				return (
					<FixedContentRow style={style}>
						<LightGreyCard padding="8px 12px" borderRadius="8px">
							<RowBetween>
								<Text small>
									{"Expanded results from inactive Token Lists"}
								</Text>
								<QuestionHelper
									text={
										"Tokens from inactive lists. Import specific tokens below or click 'Manage' to activate more lists."
									}
									className="ml-1"
								/>
							</RowBetween>
						</LightGreyCard>
					</FixedContentRow>
				);
			}

			if (showImport && token) {
				return (
					<ImportRow
						style={style}
						token={token as Token}
						showImportView={showImportView}
						setImportToken={setImportToken}
						dim
					/>
				);
			}

			return (
				<CurrencyRow
					style={style}
					currency={currency}
					isSelected={isSelected}
					onSelect={handleSelect}
					otherSelected={otherSelected}
				/>
			);
		},
		[
			selectedCurrency,
			otherCurrency,
			chainId,
			currencies.length,
			breakIndex,
			onCurrencySelect,
			showImportView,
			setImportToken,
		],
	);

	const itemKey = useCallback(
		(index: number, data: any) => currencyKey(data[index]),
		[],
	);

	return (
		<FixedSizeList
			height={250}
			ref={fixedListRef as any}
			width="100%"
			itemData={itemData}
			itemCount={itemData.length}
			itemSize={56}
			itemKey={itemKey}
		>
			{Row}
		</FixedSizeList>
	);
}
