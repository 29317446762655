import Cookie from "js-cookie";
import { useTheme as useNextTheme } from "next-themes";
import { useCallback, useContext, useEffect } from "react";
import { ThemeContext as StyledThemeContext } from "styled-components";

export const COOKIE_THEME_KEY = "theme";

const useTheme = () => {
	const { resolvedTheme, setTheme } = useNextTheme();
	const theme = useContext(StyledThemeContext);

	const handleSwitchTheme = useCallback(
		(themeValue: "light" | "dark") => {
			try {
				setTheme(themeValue);
				Cookie.set(COOKIE_THEME_KEY, themeValue);
			} catch {
				// ignore set cookie error for perp theme
			}
		},
		[setTheme],
	);

	useEffect(() => {
		handleSwitchTheme("dark");
	}, [handleSwitchTheme]);

	return {
		isDark: resolvedTheme === "dark",
		setTheme: handleSwitchTheme,
		theme,
	};
};

export default useTheme;
