import type { Currency, Token } from "@vapordex/sdk";
import type { TokenList } from "@vapordex/tokenlist";
import {
	CurrencyModalView,
	ManageTokensButton,
	TokenSelectorModalWrapper,
} from "@vaporfi/features";
import type React from "react";
import { useCallback, useState } from "react";
import CurrencySearch from "./CurrencySearch";
import ImportList from "./ImportList";
import ImportToken from "./ImportToken";
import Manage from "./Manage";

export interface CurrencySearchModalProps {
	selectedCurrency?: Currency | null;
	onCurrencySelect: (currency: Currency) => void;
	otherSelectedCurrency?: Currency | null;
	showCommonBases?: boolean;
	onDismiss?: () => void;
}

export default function CurrencySearchModal({
	onCurrencySelect,
	onDismiss = () => null,
	otherSelectedCurrency,
	selectedCurrency,
	showCommonBases = false,
}: CurrencySearchModalProps) {
	const [modalView, setModalView] = useState<CurrencyModalView>(
		CurrencyModalView.search,
	);
	const handleBackToSearch = () => {
		setModalView(CurrencyModalView.search);
	};

	const [importToken, setImportToken] = useState<Token | undefined>();
	const [importList, setImportList] = useState<TokenList | undefined>();
	const [listURL, setListUrl] = useState<string | undefined>();

	const handleCurrencySelect = useCallback(
		(currency: Currency) => {
			onDismiss?.();
			onCurrencySelect(currency);
		},
		[onDismiss, onCurrencySelect],
	);

	const config = {
		[CurrencyModalView.importList]: {
			modalProps: {},
			title: "Import List",
		},
		[CurrencyModalView.importToken]: {
			modalProps: {},
			title: "Import Token",
		},
		[CurrencyModalView.manage]: {
			modalProps: {
				onBack: handleBackToSearch,
				showBackIcon: true,
			},
			title: "Manage",
		},
		[CurrencyModalView.search]: {
			modalProps: {},
			title: "Select a Token",
		},
	};

	let modalBodyContent: React.ReactNode;
	switch (modalView) {
		case CurrencyModalView.search: {
			modalBodyContent = (
				<CurrencySearch
					onCurrencySelect={handleCurrencySelect}
					selectedCurrency={selectedCurrency}
					otherSelectedCurrency={otherSelectedCurrency}
					showCommonBases={showCommonBases}
					showImportView={() => setModalView(CurrencyModalView.importToken)}
					setImportToken={setImportToken}
				/>
			);
			break;
		}
		case CurrencyModalView.importToken: {
			if (importToken) {
				modalBodyContent = (
					<ImportToken
						tokens={[importToken]}
						handleCurrencySelect={handleCurrencySelect}
					/>
				);
			}
			break;
		}
		case CurrencyModalView.importList: {
			if (importList && listURL) {
				modalBodyContent = (
					<ImportList
						list={importList}
						listURL={listURL}
						onImport={() => setModalView(CurrencyModalView.manage)}
					/>
				);
			}
			break;
		}
		case CurrencyModalView.manage: {
			modalBodyContent = (
				<Manage
					setModalView={setModalView}
					setImportToken={setImportToken}
					setImportList={setImportList}
					setListUrl={setListUrl}
				/>
			);
			break;
		}
		default: {
			modalBodyContent = null;
		}
	}

	return (
		<TokenSelectorModalWrapper
			title={config[modalView].title}
			onDismiss={onDismiss}
			modalView={modalView}
			{...config[modalView].modalProps}
			body={
				<div className="flex h-full flex-col justify-between">
					<div className="flex-grow rounded-[10px] px-1 py-4">
						{modalBodyContent}
					</div>
					{modalView === CurrencyModalView.search && (
						<div className="flex w-full items-center justify-center bg-black pb-4 text-center">
							<ManageTokensButton
								onClick={() => setModalView(CurrencyModalView.manage)}
							/>
						</div>
					)}
				</div>
			}
		/>
	);
}
