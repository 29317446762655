import { ERC20ABI } from "@config/abi/erc20ABI";
import { type Currency, CurrencyAmount, type Token } from "@vapordex/sdk";
import { useMemo } from "react";
import type { Address } from "viem";
import { useSingleCallResult } from "../state/multicall/hooks";

function useTokenAllowance(
	token?: Token,
	owner?: string,
	spender?: string,
): CurrencyAmount<Currency> | undefined {
	const inputs = useMemo(() => [owner, spender], [owner, spender]);
	const allowance = useSingleCallResult(
		token?.address as Address,
		ERC20ABI,
		"allowance",
		inputs,
	).result;

	return useMemo(
		() =>
			token && typeof allowance === "bigint"
				? CurrencyAmount.fromRawAmount(token, allowance.toString())
				: undefined,
		[token, allowance],
	);
}

export default useTokenAllowance;
