/* eslint-disable no-restricted-syntax */
import { useAllTokens, useIsUserAddedToken, useToken } from "@hooks/Tokens";
import { neutral } from "@styles/theme/color";
import { isAddress } from "@utils";
import type { Currency, Token } from "@vapordex/sdk";
import { Box, Input, Text } from "@vapordex/uikit";
import { NATIVE_SYMBOLS } from "@vaporfi/utils";
import useActiveWagmi from "hooks/useActiveWagmi";
import useDebounce from "hooks/useDebounce";
import {
	type KeyboardEvent,
	type RefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import type { FixedSizeList } from "react-window";
import { useAllLists, useInactiveListUrls } from "state/lists/hooks";
import { type TagInfo, WrappedTokenInfo } from "state/types";
import { useAudioModeManager } from "state/user/hooks";
import styled from "styled-components";
import Column, { AutoColumn } from "../Layout/Column";
import Row from "../Layout/Row";
import CommonBases from "./CommonBases";
import CurrencyList from "./CurrencyList";
import ImportRow from "./ImportRow";
import { createFilterToken, useSortedTokensByQuery } from "./filtering";
import useTokenComparator from "./sorting";
import { getSwapSound } from "./swapSound";

interface CurrencySearchProps {
	selectedCurrency?: Currency | null;
	onCurrencySelect: (currency: Currency) => void;
	otherSelectedCurrency?: Currency | null;
	showCommonBases?: boolean;
	showImportView: () => void;
	setImportToken: (token: Token) => void;
}

const StyledInput = styled(Input)`
  height: 32px;
  font-size: 13px;
  placeholder: #d6dada;
  color: #d6dada;
  background-color: ${neutral[900]};
  border-radius: 5px;
  &::placeholder {
    color: #d6dada;
  }
`;

function useSearchInactiveTokenLists(
	search: string | undefined,
	minResults = 10,
): WrappedTokenInfo[] {
	const lists = useAllLists();
	const inactiveUrls = useInactiveListUrls();
	const { chainId } = useActiveWagmi();
	const activeTokens = useAllTokens(chainId);
	return useMemo(() => {
		if (!search || search.trim().length === 0) return [];
		const filterToken = createFilterToken(search);
		const exactMatches: WrappedTokenInfo[] = [];
		const rest: WrappedTokenInfo[] = [];
		const addressSet: { [address: string]: true } = {};
		const trimmedSearchQuery = search.toLowerCase().trim();
		for (const url of inactiveUrls) {
			const list = lists[url].current;
			// eslint-disable-next-line no-continue
			if (!list) continue;
			for (const tokenInfo of list.tokens) {
				if (
					tokenInfo.chainId === chainId &&
					!(tokenInfo.address in activeTokens) &&
					!addressSet[tokenInfo.address as string] &&
					filterToken(tokenInfo)
				) {
					const tags: TagInfo[] =
						tokenInfo.tags
							?.map((tagId) => {
								if (!list.tags?.[tagId]) return;
								return { ...list.tags[tagId], id: tagId };
							})
							?.filter(Boolean) ?? [];
					const wrapped: WrappedTokenInfo = new WrappedTokenInfo(
						tokenInfo,
						tags,
					);
					addressSet[wrapped.tokenInfo.address] = true;
					if (
						tokenInfo.name?.toLowerCase() === trimmedSearchQuery ||
						tokenInfo.symbol?.toLowerCase() === trimmedSearchQuery
					) {
						exactMatches.push(wrapped);
					} else {
						rest.push(wrapped);
					}
				}
			}
		}
		return [...exactMatches, ...rest].slice(0, minResults);
	}, [activeTokens, chainId, inactiveUrls, lists, minResults, search]);
}

function CurrencySearch({
	onCurrencySelect,
	otherSelectedCurrency,
	selectedCurrency,
	setImportToken,
	showCommonBases,
	showImportView,
}: CurrencySearchProps) {
	const { chainId, nativeCurrency } = useActiveWagmi();

	// refs for fixed size lists
	const fixedList = useRef<FixedSizeList>();

	const [searchQuery, setSearchQuery] = useState<string>("");
	const debouncedQuery = useDebounce(searchQuery, 200);

	const [invertSearchOrder] = useState<boolean>(false);

	const allTokens = useAllTokens(chainId);

	// if they input an address, use it
	const searchToken = useToken(debouncedQuery);
	const searchTokenIsAdded = useIsUserAddedToken(searchToken);

	const [audioPlay] = useAudioModeManager();

	const showNative: boolean = useMemo(() => {
		const s = debouncedQuery.toLowerCase().trim();
		return (
			s === "" ||
			NATIVE_SYMBOLS.some((symbol) => s?.startsWith(symbol?.toLowerCase()))
		);
	}, [debouncedQuery]);

	const filteredTokens: Token[] = useMemo(() => {
		const filterToken = createFilterToken(debouncedQuery);
		return Object.values(allTokens).filter(filterToken);
	}, [allTokens, debouncedQuery]);

	const filteredQueryTokens = useSortedTokensByQuery(
		filteredTokens,
		debouncedQuery,
	);

	const tokenComparator = useTokenComparator(invertSearchOrder);

	const filteredSortedTokens: Token[] = useMemo(() => {
		return [...filteredQueryTokens].sort(tokenComparator);
	}, [filteredQueryTokens, tokenComparator]);

	const handleCurrencySelect = useCallback(
		(currency: Currency) => {
			onCurrencySelect(currency);
			if (audioPlay) {
				getSwapSound().play();
			}
		},
		[audioPlay, onCurrencySelect],
	);

	// manage focus on modal show
	const inputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	const handleInput = useCallback((event) => {
		const input = event.target.value;
		const checksummedInput = isAddress(input);
		setSearchQuery(checksummedInput || input);
		fixedList.current?.scrollTo(0);
	}, []);

	const handleEnter = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Enter") {
				const s = debouncedQuery.toLowerCase().trim();
				if (NATIVE_SYMBOLS.includes(s)) {
					handleCurrencySelect(nativeCurrency);
				} else if (
					filteredSortedTokens.length > 0 &&
					(filteredSortedTokens[0].symbol?.toLowerCase() ===
						debouncedQuery.trim().toLowerCase() ||
						filteredSortedTokens.length === 1)
				) {
					handleCurrencySelect(filteredSortedTokens[0]);
				}
			}
		},
		[
			debouncedQuery,
			filteredSortedTokens,
			handleCurrencySelect,
			nativeCurrency,
		],
	);

	// if no results on main list, show option to expand into inactive
	const filteredInactiveTokens = useSearchInactiveTokenLists(debouncedQuery);

	const hasFilteredInactiveTokens = Boolean(filteredInactiveTokens?.length);

	const getCurrencyListRows = useCallback(() => {
		if (searchToken && !searchTokenIsAdded && !hasFilteredInactiveTokens) {
			return (
				<Column style={{ height: "100%", padding: "20px 0" }}>
					<ImportRow
						token={searchToken}
						showImportView={showImportView}
						setImportToken={setImportToken}
					/>
				</Column>
			);
		}

		return Boolean(filteredSortedTokens?.length) ||
			hasFilteredInactiveTokens ? (
			<Box margin="24px -24px 0px -24px">
				<CurrencyList
					height={300}
					showNative={showNative}
					currencies={filteredSortedTokens}
					inactiveCurrencies={filteredInactiveTokens}
					breakIndex={
						Boolean(filteredInactiveTokens?.length) && filteredSortedTokens
							? filteredSortedTokens.length
							: undefined
					}
					onCurrencySelect={handleCurrencySelect}
					otherCurrency={otherSelectedCurrency}
					selectedCurrency={selectedCurrency}
					fixedListRef={fixedList}
					showImportView={showImportView}
					setImportToken={setImportToken}
				/>
			</Box>
		) : (
			<Column style={{ height: "100%", padding: "20px" }}>
				<Text color="textSubtle" textAlign="center" mb="20px">
					{"No results found."}
				</Text>
			</Column>
		);
	}, [
		filteredInactiveTokens,
		filteredSortedTokens,
		handleCurrencySelect,
		hasFilteredInactiveTokens,
		otherSelectedCurrency,
		searchToken,
		searchTokenIsAdded,
		selectedCurrency,
		setImportToken,
		showNative,
		showImportView,
	]);

	return (
		<>
			<AutoColumn gap="16px">
				<Row>
					<StyledInput
						id="token-search-input"
						data-testid="token-search-input"
						placeholder={"Search by Name or Address"}
						scale="lg"
						autoComplete="off"
						value={searchQuery}
						ref={inputRef as RefObject<HTMLInputElement>}
						onChange={handleInput}
						onKeyDown={handleEnter}
					/>
				</Row>
				{showCommonBases && (
					<CommonBases
						chainId={chainId}
						onSelect={handleCurrencySelect}
						selectedCurrency={selectedCurrency}
					/>
				)}
			</AutoColumn>
			{getCurrencyListRows()}
		</>
	);
}

export default CurrencySearch;
