import styled, { type DefaultTheme } from "styled-components";
import { space, typography, variant } from "styled-system";
import type { Colors } from "../../theme/types";
import { scaleVariants, styleVariants } from "./theme";
import { type TagProps, variants } from "./types";

interface ThemedProps extends TagProps {
	theme: DefaultTheme;
}

const getOutlineStyles = ({
	outline,
	theme,
	variant: variantKey = variants.PRIMARY,
}: ThemedProps) => {
	if (outline) {
		const themeColorKey = styleVariants[variantKey]
			.backgroundColor as keyof Colors;
		const color = theme.colors[themeColorKey];

		return `
      color: ${color};
      background: ${theme.colors.background};
      border: 2px solid ${color};
    `;
	}

	return "";
};

export const StyledTag = styled.div<ThemedProps>`
  align-items: center;
  border-radius: 16px;
  color: #ffffff;
  display: inline-flex;
  font-weight: 400;
  white-space: nowrap;

  & > svg {
    fill: currentColor;
  }

  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}

  ${variant({
		prop: "scale",
		variants: scaleVariants,
	})}
  ${variant({
		variants: styleVariants,
	})}
  ${space}
  ${typography}

  ${getOutlineStyles}
`;

export default null;
